

import Vue from 'vue'
import i18n from '../local';
import store from '../store';
import previewImage from './previewImage.vue'
import previewVideo from './previewVideo.vue'
import previewAudio from './previewAudio.vue'
import resizeProgram from 'views/screens/programs/components/resizeModal'

const previewImageModal = Vue.extend(previewImage),
    previewVideoModal = Vue.extend(previewVideo),
    previewAudioModal = Vue.extend(previewAudio),
    resizeProgramModal = Vue.extend(resizeProgram);

let PreviewImage = function(data) {
        let instance = new previewImageModal({
            data,
            i18n,
            store
        }).$mount().$el
        document.body.appendChild(instance)
    },
    PreviewVideo = function(data) {
        let instance = new previewVideoModal({
            data
        }).$mount().$el
        document.body.appendChild(instance)
    },
    PreviewAudio = function(data) {
        let instance = new previewAudioModal({
            data
        }).$mount().$el
        document.body.appendChild(instance)
    },
    ResizeProgram =  function(data) {
        let instance = new resizeProgramModal({
            data,
            i18n,
            store
        }).$mount().$el
        document.body.appendChild(instance)
    };

export { PreviewImage, PreviewVideo, PreviewAudio, ResizeProgram }
