<template>
    <div class="preview-mask">
        <Icon class="closeIcon" size="40" type="ios-close-circle-outline" @click="handleColse" />
        <video ref="videoPlayer" class="video-js"></video>
    </div> 
</template>
<script>
    import videojs from 'video.js';
    import 'video.js/dist/video-js.css'
    export default {
        name: "VideoPlayer",
        mixins: [],
        components: {},
        props: [],
        data () {
            return {
                player: null,
                sources: []
            }
        },
        computed: {},
        methods: {
            handleColse() {
                this.$destroy();
                document.body.removeChild(this.$el);
                document.body.style = ""
            },
        },
        created() {},
        mounted() {
            this.$nextTick(function() {
                document.body.style.overflow = 'hidden';
                this.player = videojs(
                    this.$refs.videoPlayer,
                    {
                        controls: true,
                        autoplay: true,
                        width: "800px",
                        height: "400px",
                        sources: this.sources
                    },
                    function onPlayerReady() {
                        console.log('onPlayerReady', this);
                })

            })
        },
        beforeDestroy() {
            if (this.player) {
                this.player.dispose()
            }
        }
    }
</script>
<style scoped lang="less"> 
.video-js {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>