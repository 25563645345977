<template>
    <div class="preview-mask">
        <Icon class="closeIcon" size="40" type="ios-close-circle-outline" @click="handleColse" />
        <audio class="audio" controls height="100" width="100">
            <source v-for="(item,index) in sources" :key="index" :src="item.src" :type="item.type">
        </audio>
    </div> 
</template>
<script>
    export default {
        name: "AudioPlayer",
        mixins: [],
        components: {},
        props: [],
        data () {
            return {
                sources: []
            }
        },
        computed: {},
        methods: {
            handleColse() {
                this.$destroy();
                document.body.removeChild(this.$el);
                document.body.style = ""
            },
        },
        created() {},
        mounted() {
            this.$nextTick(function() {
                document.body.style.overflow = 'hidden';
                console.log(this.sources)

            })
        }
    }
</script>
<style scoped lang="less"> 
.audio {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>