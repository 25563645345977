<template>
    <Modal
        class="wrap"
        v-model="modal"
        title="投放设置"
        :width="1200"
        :mask-closable="false"
        :transfer="false"
    >
        <div class="content-wrap">
            <slot name="material" :materialInfo="materialInfo"></slot>
            <div v-if="showPlan" class="tabs">
                <div class="tabPane tabPaneActive">
                    选择投放屏幕（{{ checkAllGroup.length }}）
                </div>
                <div :class="['tabPane', tab==='plan'?'tabPaneActive':'']">设置播放计划</div>
            </div>
            <div v-show="tab==='device'" class="content-section" >
                <Row class="search-bar">
                    <Col span="12">
                        <Dropdown v-if="!materialInfo.category" class="dropdown" trigger="click" placement="bottom-start">
                            <Icon v-if="category" class="close" type="ios-close-circle" @click="clearCategoryList" />
                            <Button type="text">
                                产品类型<span v-if="category">:{{ category.text._ }}</span>
                                <Icon type="ios-arrow-down"></Icon>
                            </Button>
                            <DropdownMenu slot="list">
                                <DropdownItem v-for="(item, index) in terminals_category" :key="index" @click.native="handleCategoryList(item)">{{ item.text._ }}</DropdownItem>
                            </DropdownMenu>
                        </Dropdown>
                        <Dropdown v-if="is_org" class="dropdown" trigger="click" placement="bottom-start" @on-visible-change="handleTreeDropdown">
                            <Icon v-if="nodeList.length" class="close" type="ios-close-circle" @click="clearNodeList" />
                            <Button type="text">
                                组织架构
                                <div v-if="nodeList.length" class="tree line-overflow">
                                    <span>:</span>
                                    <span v-for="(item,index) in nodeList" :key="item.department_id">{{ item.title }}
                                        <span v-if="index !== nodeList.length - 1">,</span>
                                    </span>
                                </div>
                                <Icon type="ios-arrow-down"></Icon>
                            </Button>
                            <DropdownMenu slot="list">
                                <Tree :data="data" show-checkbox @on-check-change="handleTree" @on-select-change="handleSelectChange"></Tree>
                            </DropdownMenu>
                        </Dropdown>
                    </Col>
                    <Col span="12">
                        <Input v-model="query" class="float-right margin-right" clearable placeholder="搜索屏幕名称/屏幕ID" style="width: auto" @keyup.native.enter="searchNew" @on-clear="searchNew" >
                        <Icon type="ios-search" slot="suffix" @click="searchNew" />
                    </Input>
                    </Col>
                </Row>
                <no-data v-show="list.length === 0" text="暂无屏幕"></no-data>
                <CheckboxGroup v-show="list.length > 0" class="devices-wrap clearfix" v-model="checkAllGroup" @on-change="checkAllGroupChange">
                    <div class="checkbox-wrap" v-for="item in list" :key="item.rowid" >
                        <div class="img-wrap-block">
                            <div :class="['img-wrap', item.dir==='vertical'?'img-wrap-vertical':'']">
                                <img class="image-center" :src="`/cym/${item.cover_uri}`">
                                <div v-if="item.category !== 'eink_4_2_inch'" class="device-status">
                                    <Badge :status="item.state === 'online' ? 'success' : 'error'" />
                                    <span class="text">{{ item.state_text._ }}</span>
                                </div>
                            </div>
                            <span class="category">
                                <span class="category-text">{{ item.category_text._ }}</span>
                            </span>
                        </div>
                        <div v-if="checkAllGroup.length === 0" class="mask"></div>
                        <div class="device-info">
                            <div class="device-name name-wrap">
                                <div class="name line-overflow">
                                    {{ item.name }}
                                </div>
                            </div>
                            <div class="device-id line-overflow">ID:&nbsp;{{ item.serial }}</div>
                            <div v-if="item.department_name && is_org" class="device-site line-overflow">{{ item.department_name }}</div>
                        </div>
                        <Checkbox class="checkbox" :label="item.rowid"><span>&nbsp;</span></Checkbox>
                    </div>
                </CheckboxGroup>
                <Page
                    v-show="list.length > 0"
                    class="page"
                    :total="total"
                    :current="current"
                    :page-size="pageSize"
                    show-elevator
                    @on-change="nextPage" />
            </div>
            <plan-common ref="planCommon" v-show="tab==='plan'"  @on-change="handlePlanChange"></plan-common>
        </div>
        <div slot="footer" class="modal-footer">
            <template v-if="disabled">
                <Checkbox
                    v-if="list.length !== 0"
                    style="float: left;"
                    :indeterminate="indeterminate"
                    :value="checkAll"
                    @click.prevent.native="handleCheckAll">
                        <span v-if="checkAllGroup.length === 0">全选</span>
                        <span v-else>已选中{{ checkAllGroup.length }}个屏幕</span>
                    </Checkbox>
                <Button type="default" @click="cancel">{{ $t('common.cancel') }}</Button>
                <Button v-if="!showPlan" :disabled="checkAllGroup.length == 0 ? true : false "  type="primary" :loading="loading" @click="submit">确认投放</Button>
                <Button v-else :disabled="checkAllGroup.length == 0 ? true : false "  type="primary"  @click="handleNext">下一步</Button>
            </template>
            <template v-else>
                <Checkbox class="checkbox-cover" v-model="coverable" @on-change="handleCover">覆盖投放
                    <Tooltip content="投放将清空已选屏幕的播放计划，仅保留本次投放的节目" placement="right">
                        <Icon size="19" color="#000" type="md-help-circle" />
                    </Tooltip>
                </Checkbox>
                <Button type="default" @click="handlePrevious">上一步</Button>
                <Button type="primary" :loading="loading" @click="submit">立即投放</Button>
            </template>
        </div>
        <cover-modal
            :show="coverModalShow"
            :cancel="handleCoverCancel"
            :close="handleCoverClose"
        ></cover-modal>
    </Modal>  
</template>
<script>
    import planCommon from 'views/components/plan'
    import coverModal from 'views/spaces/components/cover'
    import noData from '@/views/components/noData'
    import { checkbox, dropdownTree } from '@/mixins/index'

    export default {
        name: "",
        mixins: [checkbox, dropdownTree],
        components: {
            planCommon,
            coverModal,
            noData
        },
        props: {
            show: {
                default: false
            },
            materialInfo: {
                default: () => {
                    return {}
                }
            }
        },
        data () {
            return {
                modal: false,
                loading: false,
                query: '',
                indeterminate: false,
                checkAll: false,
                checkAllGroup: [],
                current: 1,
                total: 0,
                pageSize: 12,
                list: [],
                disabled: true,
                tab: 'device',
                plan: {},
                coverable: false,
                coverModalShow: false,
                nodeList: [],
                treeList: [],
                data: [],
                category: null,
                showPlan: false
            }
        },
        computed: {
            department_id() {
                return this.$store.state.user_tree.department_id
            },
            is_org() {
                return this.$store.state.default_bucket_tokens.org
            },
            tree_data() {
                return [this.$store.state.user_tree]
            },
            terminals_category() {
                return this.$store.state.terminals_category
            }
        },
        watch: {
            show(val) {
                this.modal = val
                if (val) {
                    if (this.materialInfo.category) {
                        let category = this.terminals_category.find((item) => item.key === this.materialInfo.category)
                        this.category = category
                    }
                    this.getData()
                    this.data  = this.handleRecursive(this.tree_data)
                }
            },
            modal(val) {
                if (!val) {
                    this.cancel()
                }
            },
            checkAllGroup(list) {
                if (list.length) {
                    let result =  this.list.find(item => item.rowid === list[0])
                    if (result) {
                        this.showPlan = result.repeater_id > 0 ? false : true
                    }
                } else {
                    this.showPlan = false
                }
            }
        },
        methods: {
            handleCategoryList(item) {
                this.current = 1
                this.category = item
                this.getData()
            },
            clearCategoryList() {
                this.current = 1
                this.category = null
                this.getData()
            },
            cancel() {
                this.category = null
                this.tab = 'device'
                this.current = 1
                this.list = []
                this.disabled = true
                this.query = ''
                this.checkAllGroup = []
                this.indeterminate = false
                this.coverable = false
                this.nodeList = []
                this.treeList = []
                this.showPlan = false
                this.$refs['planCommon'].cancel()
                this.$emit('cancel')
            },
            submit() {
                let valid = false
                if (!this.showPlan) {
                    valid = true
                    this.coverable = true
                    this.plan = {
                        date: {
                            switch: true,
                            start: '',
                            end: ''
                        },
                        time: {
                            switch: true,
                            start: '',
                            end: ''
                        },
                        week: {
                            switch: true,
                            weeks: []
                        }
                    }
                } else {
                    valid = this.$refs.planCommon.handleSubmit()
                }
                if (valid) {
                    let str = '',
                        len = this.checkAllGroup.length,
                        url = '';
                    for (let i = 0; i < len; i++) {
                        str += this.checkAllGroup[i]
                        if (i !== len -1) {
                            str += ','
                        }
                    }
                    let params = {
                        resource: {
                            type: this.materialInfo.type,
                            rowid: this.materialInfo.rowid
                        },
                        plan: this.plan,
                        is_override: this.coverable
                    }
                    this.loading = true
                    if (this.showPlan) {
                        url = `terminals/${str}/plans`
                    } else {
                        url = 'terminals/plans'
                        params.terminal_rowid_list = this.checkAllGroup
                        params.commodity_rowid_list = []
                    }
                    this.$api.post(url, params).then((
                        ) => {
                        this.$emit('confirm')
                        this.cancel()
                        this.$Message.success('成功')
                    }).catch(() => {}).finally(() => {
                        this.loading = false
                    })
                }
            },
            handleNext() {
                this.disabled = !this.disabled
                this.tab = 'plan'
                // this.$refs.planCommon.initTime();
            },
            handlePrevious() {
                this.disabled = !this.disabled
                this.tab = 'device'
            },
            searchNew() {
                if (this.loading) return
                this.current = 1
                this.getData()
            },
            getData() {
                let params = {
                    query: this.query,
                    dir: this.materialInfo.mode,
                    start: (this.current - 1) * this.pageSize,
                    count: this.pageSize,
                    repeater_type: 'all'
                }

                if (this.is_org) {
                    let len = this.nodeList.length
                    if (len) {
                        let department_id_list = []
                        this.nodeList.map((item) => {
                            department_id_list.push(item.department_id)
                        })
                        params.department_id_list = JSON.stringify(department_id_list)
                    } else {
                        this.treeList = []
                        this.getTreeList(this.tree_data, 'department_id')
                        params.department_id_list = JSON.stringify(this.treeList)
                    }
                } else {
                    params.department_id = this.department_id

                }

                if (this.category) params.category = this.category.key
                if (this.materialInfo.category && !this.category) params.category = this.materialInfo.category
                if (!this.materialInfo.category) params.repeater_type = 'display'
                if (this.materialInfo.target_size) {
                    params.target_size = this.materialInfo.target_size
                    delete params.category
                }
                this.loading = true
                //解决翻页后，显示上页勾选状态问题
                let store = [...this.checkAllGroup]
                this.checkAllGroup = []
                this.checkAll = false;

                this.$api.get('terminals/search', { params }).then(({ data, total_count, page }) => {
                    if (page === this.current) {
                        let isAll = true
                        let set = new Set(store)
                        //检测是否当页全选，当前页全勾选，显示全选状态
                        data.map((item)=>{
                            if (!set.has(item.rowid) || store.length == 0) {
                                isAll = false
                            }
                        })
                        if (isAll) {
                            this.indeterminate = false;
                            this.checkAll = true;
                        }

                        this.list = data
                        this.total = total_count
                        this.loading = false
                    }
                }).catch(()=>{
                    this.loading = false
                }).finally(()=>{
                    this.checkAllGroup = store
                })

            },
            nextPage(e) {
                this.current = e
                this.getData()
            },
            handlePlanChange(params) {
                this.plan = params
            },
            handleCover(e) {
                if (!e) return
                this.coverModalShow = true
            },
            handleCoverCancel() {
                this.coverable = false
                this.handleCoverClose()
            },
            handleCoverClose() {
                this.coverModalShow = false

            }
        },
        created() {}
    }
</script>
<style scoped lang="less">
@import "~less/color";
.content-wrap {
    padding: 0 18px;
    .tabs {
        width: 100%;
        margin-bottom: 27px;
        .tabPane {
            position: relative;
            display: inline-block;
            padding-bottom: 15px;
            width: 50%;
            border-radius: 10px 10px 0px 0px;
            font-size: 18px;
            line-height: 29px;
            text-align: center;
            color: #9a9a9a;
            font-weight: 400;
            &::after {
                position: absolute;
                content: '\200B';
                left: 0;
                bottom: 0;
                width: 100%;
                height: 3px;
                background: #ededed;
            }
        }
        .tabPaneActive {
            color: #000;
            &::after {
                background: @primary-color;
            }
        }
    }
    .content-section {
        .devices-wrap {
            overflow-x: hidden;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: flex-start;
            align-items: center;
            width: 1140px;
            .checkbox-wrap {
                position: relative;
                display: inline-block;
                margin: 0 8px 31px 0;
                padding-left: 0.7%;
                border: 1px solid #d3d3d3;
                width: calc(25% - 8px);
                padding-top: 13%;
                background: #fff;
                &:hover {
                    .mask {
                        opacity: 1;
                    }
                }
                .mask {
                    opacity: 0;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(0,0,0,.08);
                    transition: opacity .3s ease;
                }
                .checkbox {
                    position: absolute;
                    top: 0;
                    left: 0;
                    margin-right: 0;
                    padding: 5px;
                }
                .checked {
                    display: inline-block;
                    width: 100%;
                    height: 100%;

                }
                .img-wrap-block {
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 38%;
                    height: 100%;
                    background: #f6f7f9;
                    .category {
                        position: absolute;
                        left: 12%;
                        bottom: 4%;
                        height: 16px;
                        padding: 0 2px;
                        border-radius: 8px;
                        background: @primary-color;
                        color: #ffff;
                        font-size: 10px;
                        text-align: center;
                        line-height: 16px;
                        white-space:nowrap;
                        .category-text {
                            display: block;
                            transform: scale(0.8);
                        }
                    }
                    .img-wrap {
                        position: absolute;
                        top: 31%;
                        bottom: 31%;
                        left: 10%;
                        right: 10%;
                        .device-status {
                            position: absolute;
                            left: 5%;
                            bottom: 12%;
                            width: 40px;
                            height: 16px;
                            padding-left: 5px;
                            border-radius: 4px;
                            background: #000;
                            color: #fff;
                            line-height: 16px;
                            .ivu-badge-status {
                                width: 10px;
                                height: 100%;
                            }
                            .text {
                                position: absolute;
                                left: 12px;
                                top: 50%;
                                transform: translateY(-50%) scale(0.8);
                                font-size: 10px;
                                display: block;
                            }
                        }
                    }
                    .img-wrap-vertical {
                        position: absolute;
                        top: 15%;
                        bottom: 19%;
                        left: 25%;
                        right: 25%;
                        .category {
                            left: 0%;
                            top: 7%;
                        }
                        .device-status {
                            position: absolute;
                            left: 8%;
                            bottom: 7%;
                        }
                    }
                }
                .device-info {
                    position: absolute;
                    top: 50%;
                    left: 40%;
                    transform: translateY(-50%);
                    width: 58%;
                    min-width: 100px;
                    .device-name {
                        position: relative;
                        height: 33px;
                        font-weight: 700;
                        font-size: 10px;
                        width: calc(100% + 16px);
                        .name {
                            display: inline-block;
                            position: relative;
                            max-width: calc(100% - 24px);
                            font-weight: 700;
                            font-size: 10px;
                            line-height: 33px;
                            vertical-align: middle;
                        }
                        .icon {
                            vertical-align: middle;
                        }
                    }
                    .device-id {
                        font-size: 10px;
                        font-weight: 700;
                    }
                    .device-site {
                        font-size: 10px;
                    }
                    .device-plan {
                        display: inline-block;
                        margin-top: 4px;
                        padding: 0 10px;
                        height: 28px;
                        max-width: 100px;
                        background: @primary-color;
                        border-radius: 4px;
                        color: #fff;
                        text-align: center;
                        line-height: 28px;
                        font-size: 12px;
                    }
                }
                .checkbox {
                    position: absolute;
                    left: 0;
                    top: 0;
                    margin-right: 0;
                    padding: 10px 0 0 18px;
                    width: 100%;
                    height: 100%;
                }
            }
        }
        .dir {
            position: relative;
            margin-left: -15px;
            .close {
                display: none;
            }
            &:hover {
                .close {
                    display: block;
                    position: absolute;
                    right: 0px;
                    top: 0px;
                }
            }
        }
        .dropdown {
            position: relative;
            .close {
                display: none;
            }
            &:hover {
                .close {
                    display: block;
                    position: absolute;
                    right: 0px;
                    top: 0px;
                }
            }
            .tree {
                display: inline-block;
                max-width: 100px;
                vertical-align: middle;
            }

        }
    }
}
.checkbox-cover {
    position: absolute;
    right: 30px;
    bottom: 56px;
}
</style>