<template>
    <div class="template-crop-mask">
        <div class="template-crop-wrap">
            <div class="template-crop-aside">
                <Icon class="closeIcon pointer" size="31" type="ios-close" @click="handleColse" />
                <div class="aside-title">调整节目尺寸</div>
                <div v-if="programInfo.orientation==='horizontal'" class="horizontal-info">
                    <div class="horizontal-info-title">横屏</div>
                    <div v-for="item in models" :key="item.model" :class="['horizontal-info-item', item.width==newLayouts[programInfo.orientation].width&&item.height==newLayouts[programInfo.orientation].height?'selected':'']" @click="handleSize(item.model, item.model_orientation_text._)">{{ item.model_orientation_text._ }}</div>
                </div>
                <div v-if="programInfo.orientation==='vertical'" class="vertical-info">
                    <div class="vertical-info-title">竖屏</div>
                    <div v-for="item in models" :key="item.model" :class="['horizontal-info-item', item.width==newLayouts[programInfo.orientation].width&&item.height==newLayouts[programInfo.orientation].height?'selected':'']" @click="handleSize(item.model, item.model_orientation_text._)">{{ item.model_orientation_text._ }}</div>
                </div>
                <div class="info-input-wrap">
                    <Form ref="formValidate" :model="newLayouts" inline>
                        <FormItem prop="width">
                            <InputNumber class="info-width" disabled :min="0" v-model="newLayouts[programInfo.orientation].width"></InputNumber>
                        </FormItem>
                        <FormItem><span>X</span></FormItem>
                        <FormItem prop="height">
                            <InputNumber class="info-height" disabled :min="0" v-model="newLayouts[programInfo.orientation].height"></InputNumber>
                        </FormItem>
                        <FormItem><span>PX(像素)</span></FormItem>
                    </Form>
                </div>
                <div class="submit-button pointer" @click="handleSubmit">调整尺寸</div>
            </div>
        </div>
    </div>  
</template>
<script>
    import utils from '@/libs/util'

    export default {
        name: "",
        mixins: [],
        components: {},
        props: {},
        data () {
            return {
                origionTmpl: {},
                handleChanged: Function,
                newLayouts: {
                    horizontal: {},
                    vertical: {}
                },
                programInfo: {},
                scale: 1,
                layouts: {},
                models: [],
                model: null,
                model_text: ''
            }
        },
        computed: {},
        methods: {
            handleColse() {
                this.$destroy();
                document.body.removeChild(this.$el);
                document.body.style = ""
            },
            getModels() {
                let params = {
                    orientation: this.programInfo.orientation
                }
                this.$api.get('programs/layout_models', { params }).then(({ layouts }) => {
                    this.models = layouts
                })
            },
            getLayouts(model=null) {
                let params = {
                    model: model
                }
                this.$api.get('programs/layouts', { params }).then(({ layouts }) => {
                    this.layouts[model] = layouts
                })
            },
            handleSize(model, text) {
                this.model = model
                this.model_text = text
                this.newLayouts = utils.deepClone(this.layouts[model])
                for (let orientation in this.origionTmpl) {
                    let combinations = this.origionTmpl[orientation].combinations
                    combinations.map((combination, combinationIndex) => {
                        combination.layouts.map((layout, layoutIndex) => {
                            layout.cells.map((cell, cellIndex) => {
                                let available = this.newLayouts[orientation].combinations[combinationIndex].layouts[layoutIndex] ? true : false
                                if (available) {
                                    let materials = cell.materials ? utils.deepClone(cell.materials) : []
                                    if (materials.length) {
                                        let targetCell = this.newLayouts[orientation].combinations[combinationIndex].layouts[layoutIndex].cells[cellIndex]
                                        if (cell.conIndex !== undefined) {
                                            targetCell.conIndex = cell.conIndex
                                        }
                                        materials.forEach((material) => {
                                            let scale_x = targetCell.width / cell.width,
                                                scale_y = targetCell.height / cell.height,
                                                scale = Math.min(scale_x, scale_y);
                                            let diff_w = cell.width * scale - targetCell.width,
                                                diff_h = cell.height * scale - targetCell.height;
                                            material.x = material.x * scale - diff_w / 2
                                            material.y = material.y * scale - diff_h /  2
                                            material.width *= scale
                                            material.height *= scale
                                        })
                                        targetCell.materials = materials
                                    }
                                }
                            })
                        })
                    })
                }
            },
            handleSubmit() {
                this.handleColse()
                this.handleChanged({ model: this.model, model_text: this.model_text, layouts: this.newLayouts })
            }
        },
        mounted() {
            this.$nextTick(function() {
                this.newLayouts = utils.deepClone(this.origionTmpl)
                // this.getModels()
                this.getLayouts('10inches')
                this.getLayouts('24_32_43_55inches')
            })
        }
    }
</script>
<style scoped lang="less">
@import '~less/color';
.template-crop-mask {
    overflow: auto;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9998;
    height: 100%;
    background-color: rgba(55, 55, 55, 0.6);
    .template-crop-wrap {
        position: relative;
        top: 100px;
        margin: 0 auto;
        width: 416px;
        height: 500px;
        .template-crop-aside {
            padding: 40px 40px 0;
            width: 416px;
            height: 100%;
            background: #fff;
            border-radius: 6px;
            box-shadow: 0 4px 12px rgba(0,0,0,0.15);
            font-size: 14px;
            .closeIcon {
                position: absolute;
                right: 10px;
                top: 10px;
                color: #999;
                &:hover {
                    color: #444;
                }
            }
            .aside-title {
                margin-bottom: 68px;
                font-size: 16px;
                line-height: 20px;
            }
            .horizontal-info, .vertical-info {
                margin-bottom: 16px;
                border: 1px solid #eaeaea;
                border-radius: 5px;
                .horizontal-info-title, .vertical-info-title {
                    padding-left: 16px;
                    line-height: 28px;
                }
                .horizontal-info-item, .vertical-info-item {
                    cursor: pointer;
                    padding-left: 16px;
                    height: 37px;
                    color: #afb4ba;
                    line-height: 37px;
                }
                .selected {
                    background: #f0f3f4;
                    color: @primary-color;
                }
            }
            .info-input-wrap {
                .info-width, .info-height {
                    margin-right: 8px;
                }
                .info-height {
                    margin-left: 8px;
                }
            }
            .submit-button {
                margin-top: 30px;
                width: 330px;
                height: 46px;
                border-radius: 2px;
                background: @primary-color;
                color: #fff;
                font-size: 16px;
                line-height: 46px;
                text-align: center;
            }
        }
    }
}
</style>