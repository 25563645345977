<template>
    <Modal
        class="wrap"
        v-model="modal"
        :title="title"
        :transfer="transfer"
        :mask-closable="false"
    >
        <Form ref="formValidate" class="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="80">
            <FormItem label="绑定码" prop="binding_token">
                <Input v-model="formValidate.binding_token" placeholder="请输入绑定码"></Input>
            </FormItem>
            <FormItem label="屏幕名称" prop="name">
                <Input v-model="formValidate.name" placeholder="请输入屏幕名称"></Input>
            </FormItem>
            <FormItem label="屏幕方向" prop="dir">
                <Select v-model="formValidate.dir" placeholder="请选择屏幕方向" transfer>
                    <Option v-for="(item, index) in dirList" :key="index" :value="item.dir">{{ item.text._ }}</Option>
                </Select>
            </FormItem>
        </Form>
        <div slot="footer" style="margin-right: 15%;">
            <Button type="default" @click="cancel">{{ $t('common.cancel') }}</Button>
            <Button type="primary" :loading="loading" @click="submit">{{ $t('common.confirm') }}</Button>
        </div>
    </Modal>  
</template>
<script>
    export default {
        name: "",
        mixins: [],
        components: {},
        props: {
            show: {
                default: false
            },
            department_id: {
                default: null
            },
            title: {
                default: ''
            },
            transfer: {
                default: true
            }
        },
        data () {
            return {
                loading: false,
                modal: false,
                formValidate: {
                    name: '',
                    binding_token: '',
                    dir: ''
                },
                ruleValidate: {
                    name: [
                        { required: true, message: '请输入屏幕名称', trigger: 'blur' },
                        { type: 'string', max: 32, message: '最多32个字符', trigger: 'blur' }
                    ],
                    binding_token: [
                        { required: true, message: '请输入绑定码', trigger: 'blur' }
                    ],
                    dir: [
                        { required: true, message: '请选择屏幕方向', trigger: 'change' }
                    ]
                },
                dirList: []
            }
        },
        computed: {},
        watch: {
            show(val) {
                this.modal = val
                if (val) {
                    this.formValidate.dir = 'horizontal'
                }
            },
            modal(val) {
                if (!val) {
                    this.cancel()
                }
            }
        },
        methods: {
            cancel() {
                this.$emit('cancel')
                this.$refs['formValidate'].resetFields();
            },
            submit() {
                this.$refs['formValidate'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        this.$api.post('terminals', { ...this.formValidate, department_id: this.department_id }).then(() => {
                            this.$Message.success(this.$t('message.success'));
                            this.$store.dispatch('GenerateRoutes').finally(() => {
                                this.$emit('confirm')
                                this.cancel()
                                this.loading = false
                            })
                        }).catch(() => {
                            this.loading = false
                        })
                    }
                })
            },
            getDirList() {
                this.$api.get('terminals/dir/list').then(({ data }) => {
                    this.dirList = data
                })
            }
        },
        mounted() {
            this.getDirList()
        }
    }
</script>
<style scoped>
.formValidate {
    width: 60%;
}
</style>