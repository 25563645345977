<template>
    <div class="preview-mask">
        <div class="swiper-wrap">
            <div class="closeIconWrap clearfix">
                <Icon class="closeIcon" size="31" type="ios-close" @click="handleColse" />
            </div>
            <div class="column-wrap">
                <swiper class="mySwiper" ref="mySwiper" :options="swiperOptions" :auto-update="true">
                    <swiper-slide
                        class="imgWrap"
                        v-for="(item) in sources"
                        :key="item.rowid"
                    >
                        <!-- 图片、音频懒加载 -->
                        <div v-if="item.type==='image'" class="swiper-zoom-container">
                            <img ref="media" :class="['media  swiper-lazy', item.material_type==='template'?'template-preview-image-bkg':'']" :data-src="item.src" ondragstart="return false;">
                        </div>
                        <audio v-else-if="item.type==='audio'" ref="media" class="audio media swiper-lazy" controls height="100" width="100" :data-src="item.src"></audio>
                        <!-- controlsList="nodownload" οncοntextmenu="return false" -->
                        <!-- 视频懒加 -->
                        <video-component
                            v-else
                            ref="media"
                            class="media"
                        ></video-component>
                    </swiper-slide>
                    <div class="swiper-button-prev" slot="button-prev"></div>
                    <!--左箭头。如果放置在swiper-container外面，需要自定义样式。-->
                    <div class="swiper-button-next" slot="button-next"></div>
                    <!--右箭头。如果放置在swiper-container外面，需要自定义样式。-->
                    <!-- <div class="swiper-pagination" slot="pagination"></div> -->
                </swiper>
                <div class="info-block">
                    <h2 class="info-title">基本信息</h2>
                    <ul class="info-content-wrap">
                        <li class="name-wrap">
                            <span v-if="currentItem.rowid && from !== 'template'" :class="currentItem.allow?'pointer':''" :key="currentItem.rowid" @click="currentItem.allow?showElement($event, currentItem.rowid, 20):''">
                                <div class="name line-overflow" >
                                    {{ currentItem.name }}
                                </div>
                                <Icon v-if="currentItem.allow" class="icon" type="md-create" />
                            </span>
                            <span v-else class="name line-overflow">{{ currentItem.name }}</span>
                        </li>
                        <li v-if="currentItem.rowid" class="action-block">
                            <Button v-if="currentItem.add_to_plan_flag&&(from === 'company'||from==='person'&&!is_org)" class="btn margin-right" type="primary" @click="handlePaly">立即投放</Button>
                            <Button v-if="from==='template'" class="margin-right" type="primary" @click="handleBeforeUseTmpl(currentItem.rowid)">使用模板</Button>
                            <Dropdown v-if="from!=='template' && currentItem.allow" class="dropdown" placement="bottom-start">
                                <a href="javascript:void(0)">
                                    <div class="icon-wrap">
                                        <Icon class="icon image-center" type="md-more" size="20" />
                                    </div>
                                </a>
                                <DropdownMenu slot="list">
                                    <DropdownItem
                                        v-if="currentItem.material_type==='template'"
                                        @click.native="handleCopy">创建副本</DropdownItem>
                                    <DropdownItem
                                        v-if="currentItem.material_type==='template'"
                                        @click.native="handleEditTmpl([currentItem.rowid], currentItem.material_type2)">编辑</DropdownItem>
                                    <DropdownItem @click.native="handelLabel">编辑标签</DropdownItem>
                                    <DropdownItem @click.native="handleRename2">重命名</DropdownItem>
                                    <DropdownItem v-if="isUser" @click.native="handleCopy2">复制到企业</DropdownItem>
                                    <DropdownItem @click.native="handleRemove2">删除</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </li>
                        <li class="info-item">
                            <span>类型</span>
                            <span>{{ currentItem.type_text }}</span>
                        </li>
                        <li class="info-item">
                            <span>格式</span>
                            <span>{{ currentItem.format_name }}</span>
                        </li>
                        <li v-if="currentItem.resolution" class="info-item">
                            <span>尺寸</span>
                            <span>{{ currentItem.resolution }}</span>
                        </li>
                        <li v-if="currentItem.bytes_size && from !== 'template'" class="info-item">
                            <span>大小</span>
                            <span>{{ currentItem.bytes_size }}</span>
                        </li>
                        <li v-if="currentItem.create_time && from !== 'template'" class="info-item">
                            <span>上传时间</span>
                            <span>{{ currentItem.create_time }}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="controls-panel" v-if="currentItem.type === 'image'">
                <i class="larger iconfont icon-fangda1 pointer" @click="handleZommin"></i>
                <i class="smaller iconfont icon-suoxiao1 pointer" @click="handleZommout"></i>
            </div>
        </div>
        <playSetting
            :show="playModalShow"
            :materialInfo="materialInfo"
            @confirm="handlePlayConfirm"
            @cancel="handlePlayCancel"
        >
            <div class="material-block" slot="material" :slot-scope="{ materialInfo }">
                <div class="img-wrap">
                    <img v-if="materialInfo.uri" class="image-center" :src="`${materialInfo.uri}`">
                </div>
                <div class="info">
                    <p>素材名称：{{ materialInfo.name }}</p>
                    <p>分辨率：{{ materialInfo.resolution }}</p>
                    <p>素材大小：{{ materialInfo.bytes_size }}</p>
                </div>
            </div>
        </playSetting>
        <edit-label
            :show="labelModalShow"
            :bucket_token="bucket_token"
            :material_ids="material_ids"
            @refresh="handleRefreshLabel"
            @confirm="handleLabelConfirm"
            @cancel="handleLabelCancel"
        ></edit-label>
        <confirm-modal
            :show="confirmModalShow"
            title="删除提示"
            content="删除资源后将无法重新找回，请谨慎操作！"
            okText="确定删除"
            cancelText="取消"
            :handleParentSubmit="handleConfirm"
            @cancel="handleCancel"
        ></confirm-modal>
        <add-modal
            :show="addModalShow"
            :department_id="department_id"
            title="添加屏幕方可正常使用"
            :transfer="false"
            @cancel="handleAddCancel"
        ></add-modal>
    </div>
</template>
<script>
    import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
    import 'swiper/css/swiper.css'
    import videoComponent from '@/components/video'
    import { rename } from '@/mixins/index'
    import playSetting from 'views/components/playSetting'
    import editLabel from '@/views/spaces/components/editLabel'
    import confirmModal from '@/views/components/confirm'
    import addModal from '@/views/screens/mediaScreens/components/add'


    export default {
        name: "",
        mixins: [rename],
        components: {
            Swiper,
            SwiperSlide,
            videoComponent,
            playSetting,
            editLabel,
            confirmModal,
            addModal
        },
        directives: {
            swiper: directive
        },
        props: {},
        data () {
            return {
                handleParentRename: Function,
                handleRemove: Function,
                handleRefreshLabel: Function,
                handleUseTmpl: Function,
                handleCopyTmpl: Function,
                handleEditTmpl: Function,
                sources: [],
                index: 0,
                from: '',
                swiperOptions: {
                    observer: true,
                    observeSlideChildren: true,
                    resizeObserver: true,
                    zoom : {
                        maxRatio: 1,
                        minRatio: 1,
                        toggle: false
                    },
                    allowTouchMove: false,
                    lazy: {
                        loadPrevNext: true,
                        loadPrevNextAmount: 1
                    },
                    init: false,
                    watchOverflow: true,
                    slidesPerView: 1,
                    freeMode: true,
                    freeModeSticky : true,
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: false
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    },
                    // Some Swiper option/callback...
                    on: {
                        slideChangeTransitionEnd: () => {
                            if(this.swiper.isEnd) {
                                this.swiper.navigation.$nextEl.css('display','none');
                            } else {
                                this.swiper.navigation.$nextEl.css('display','block');
                            }

                            let ele = document.getElementsByClassName('media')
                            if (this.sources[this.swiper.activeIndex].type === 'video') {
                                var slides = document.querySelectorAll('.swiper-slide'),
                                    slide = slides[this.swiper.activeIndex].querySelector('.media');
                                slide.player.src([{
                                    src: this.sources[this.swiper.activeIndex].src,
                                    type: 'video/mp4'
                                }])
                                slide.player.play()
                            } else if (this.sources[this.swiper.activeIndex].type === 'audio') {
                                ele[this.swiper.activeIndex].play()
                            }
                        },
                        slideChangeTransitionStart: () => {
                            //恢复放大倍数至初始值
                            this.swiper.params.zoom.maxRatio = 1

                            this.currentItem = this.sources[this.swiper.activeIndex]
                            if(this.swiper.isBeginning) {
                                this.swiper.navigation.$prevEl.css('display','none');
                            } else {
                                this.swiper.navigation.$prevEl.css('display','block');
                            }
                            let ele = document.getElementsByClassName('media')
                            if (this.sources[this.swiper.previousIndex].type === 'video') {
                                var slides = document.querySelectorAll('.swiper-slide'),
                                    slide = slides[this.swiper.previousIndex].querySelector('.media');
                                slide.player.pause()
                                slide.player.currentTime(0)
                            } else if (this.sources[this.swiper.previousIndex].type === 'audio') {
                                ele[this.swiper.previousIndex].pause()
                                ele[this.swiper.previousIndex].currentTime = 0
                            }
                        },
                        update: () => {
                            var slides = document.querySelectorAll('.swiper-slide'),
                                slide = slides[this.swiper.activeIndex].querySelector('.media'),
                                dataSrc = slide.getAttribute('data-src');
                            if (dataSrc) {
                                slide.setAttribute('src', dataSrc)
                                slide.removeAttribute('data-src')
                            }
                        }
                    },
                },
                currentItem: {
                    user: false
                },
                materialInfo: {},
                playModalShow: false,
                labelModalShow: false,
                material_ids: [],
                bucket_token: null,
                confirmModalShow: false,
                department_id: null,
                addModalShow: false
            }
        },
        computed: {
            is_org() {
                return this.$store.state.default_bucket_tokens.org
            },
            swiper() {
                return this.$refs.mySwiper.$swiper
            },
            isUser() {
                if (this.$store.state.default_bucket_tokens.org) {
                    if (this.currentItem.bucket_token === this.$store.state.default_bucket_tokens.user) {
                        return  true
                    }
                    return false
                }
                return false
            }
        },
        methods: {
            handleBeforeUseTmpl(rowid) {
                if (this.$store.state.rolesList.length === 0) return this.addModalShow = true
                this.handleUseTmpl(rowid)
            },
            handleAddCancel() {
                this.addModalShow = false
            },
            handleZommout() {
                let maxRatio = this.swiper.params.zoom.maxRatio.toFixed(1)
                if (maxRatio <= 0.1) return
                this.swiper.params.zoom.maxRatio = maxRatio - 0.1
                this.swiper.zoom.in();
            },
            handleZommin() {
                let maxRatio = this.swiper.params.zoom.maxRatio.toFixed(1)
                if (maxRatio >= 3) return
                this.swiper.params.zoom.maxRatio += 0.1
                this.swiper.zoom.in();
            },
            handleColse() {
                this.$destroy();
                document.body.removeChild(this.$el);
                document.body.style = ""
                this.swiper.destroy();
            },
            pre() {
                this.swiper.slidePrev()
            },
            next() {
                this.swiper.slideNext()
            },
            handleRename(name, rowid) {
                this.handleParentRename(name, rowid)
                this.sources[this.swiper.activeIndex].name = name
            },
            handleRename2() {
                let ele = document.querySelector('.preview-mask').querySelector('.name')
                ele.click()
            },
            handleRemove2() {
                this.confirmModalShow = true
            },
            async handleConfirm (done) {
                await this.handleRemove(this.currentItem.rowid, this.currentItem.bucket_token)
                this.sources.splice(this.swiper.activeIndex, 1)
                this.swiper.removeSlide(this.swiper.activeIndex)
                this.swiper.lazy.load()
                if (this.sources.length) {
                    //删除后自动播放下一个媒体
                    let ele = document.getElementsByClassName('media')
                    if (this.sources[this.swiper.activeIndex].type === 'video') {
                        this.$nextTick(() => {
                            this.$refs['media'][this.swiper.activeIndex].player.src([{
                                src: this.sources[this.swiper.activeIndex].src,
                                type: 'video/mp4'
                            }])
                            this.$refs['media'][this.swiper.activeIndex].player.play()
                        })
                    } else if (this.sources[this.swiper.activeIndex].type === 'audio') {
                        ele[this.swiper.activeIndex].play()
                    }
                    this.currentItem = this.sources[this.swiper.activeIndex]
                    //隐藏首、末箭头按钮
                    if (this.swiper.activeIndex === 0) {
                        this.swiper.navigation.$prevEl.css('display','none');
                    }
                    if (this.swiper.activeIndex === this.sources.length - 1) {
                        this.swiper.navigation.$nextEl.css('display','none');
                    }
                } else {
                    this.currentItem = {}
                    this.handleColse()
                }
                done()
            },
            handleCancel() {
                this.confirmModalShow = false
            },
            handlePaly() {
                let src = this.currentItem.src
                if (this.currentItem.material_type === 'video') {
                    src = this.currentItem.src.replace(/preview/, 'thumbnail')
                }
                this.materialInfo = {
                    type: 'material',
                    rowid: this.currentItem.rowid,
                    name: this.currentItem.name,
                    uri: src,
                    resolution: this.currentItem.resolution,
                    bytes_size: this.currentItem.bytes_size,
                    target_size: this.currentItem.target_size,
                    category: this.currentItem.category
                }
                this.playModalShow = true
            },
            handlePlayConfirm() {
                this.handlePlayCancel()
            },
            handlePlayCancel() {
                this.playModalShow = false
            },
            handelLabel() {
                this.labelModalShow = true
                this.material_ids = [this.currentItem.rowid]
                this.bucket_token = this.currentItem.bucket_token
            },
            handleLabelConfirm() {
                this.handleLabelCancel()
            },
            handleLabelCancel() {
                this.labelModalShow = false
            },
            handleCopy() {
                this.handleCopyTmpl([this.currentItem.rowid], this).then(() => {
                    this.$Message.success('成功')
                })
            },
            handleCopy2() {
                this.$api.post(`materials/copy?rowid_list=${this.currentItem.rowid}&&dist_bucket_token=${this.$store.state.default_bucket_tokens.org}`).then(()=>{
                    this.$Message.success('成功')
                })
            },
            EscClose(e) {
                if (e.keyCode === 27) {
                    this.handleColse()
                }
            }
        },
        created() {

        },
        mounted() {
            this.$nextTick(function() {
                this.swiper.init()
                this.swiper.slideTo(this.index, 0, false)
                this.currentItem = this.sources[this.index]
                document.body.style.overflow = 'hidden';
                //自动播放媒体
                let ele = document.getElementsByClassName('media')[this.index]
                if (this.sources[this.index].type === 'video') {
                    this.$refs['media'][this.index].player.src([{
                        src: this.sources[this.index].src,
                        type: 'video/mp4'
                    }])
                    this.$refs['media'][this.index].player.play()
                } else if (this.sources[this.index].type === 'audio') {
                    setTimeout(() => {
                        ele.play()
                    }, 1000)
                }
                //隐藏首、末箭头按钮
                if (this.index === 0) {
                    this.swiper.navigation.$prevEl.css('display','none');
                }
                if (this.index === this.sources.length - 1) {
                    this.swiper.navigation.$nextEl.css('display','none');
                }

            })
            document.addEventListener('keydown', this.EscClose);
        },
        beforeDestroy() {
            document.removeEventListener('keydown', this.EscClose);
            this.swiper.destroy();
        }
    }
</script>
<style lang="less" scoped>
@import "~less/base";
@import "~less/color";
@deep: ~">>>";

.preview-mask {
    overflow: auto;
}
/*.preview-mask @{deep} .swiper-container {
    position: relative;
    height: 100vh;
}*/
.swiper-wrap {
    position: relative;
    padding-bottom: 50px;
    height: 100%;
    width: 100%;
    background: #fff;
    border-radius: 6px;
    box-shadow: 0 4px 12px rgba(0,0,0,0.15);
    .closeIconWrap {
        margin-bottom: 10px;
        padding: 10px 10px 0 0;
        height: 40px;
        .closeIcon {
            float: right;
            cursor: pointer;
            color: #999;
            &:hover {
                color: #444;
            }
        }
    }
    .imgWrap {
        overflow: auto;
        position: relative;
    }
    .column-wrap {
        position: relative;
        display: flex;
        align-items: top;
        justify-content: space-between;
        width: 100%;
        height: calc(100vh - 100px);
        .mySwiper {
            display: inline-block;
            width: calc(100% - 253px);
            height: calc(100vh - 100px);
            background: #cfcfcf;
            box-shadow: 0px 3px 6px 0px rgba(207,207,207,0.50);
        }
        .info-block {
            overflow-y: auto;
            display: inline-block;
            padding: 0 16px;
            width: 253px;
            height: 100%;
            .info-title {
                position: relative;
                font-size: 26px;
                font-weight: 700;
                color: #092c4c;
                line-height: 30px;
                &::after {
                    content: '\200B';
                    position: absolute;
                    left: 0;
                    bottom: -10px;
                    width: 80px;
                    height: 5px;
                    background: @primary-color;
                    border-radius: 3px;
                }
            }
            .info-content-wrap {
                margin-top: 30px;
                .name-wrap {
                    margin-bottom: 20px;
                    span {
                        font-size: 20px;
                        line-height: 24px;
                        font-weight: 500;
                        color: #000;
                        .name {
                            display: inline-block;
                            max-width: 200px;
                            vertical-align: middle;
                        }
                        .icon {
                            vertical-align: middle;
                        }
                    }
                    > .name {
                        display: inline-block;
                        max-width: 221px;
                        vertical-align: middle;
                    }
                }
                .action-block {
                    margin-bottom: 60px;
                    .btn {
                        vertical-align: middle;
                    }
                    .dropdown {
                        vertical-align: middle;
                        position: relative;
                        .icon-wrap {
                            position: relative;
                            width: 32px;
                            height: 32px;
                            border-radius: 50%;
                            background: rgba(0,0,0,0.4);
                            .icon {
                                color: #fff;
                            }
                        }
                    }
                }
                .info-item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 30px;
                    font-size: 16px;
                    span {
                        display: inline-block;
                        max-width: 240px;
                        vertical-align: middle;
                    }
                }
            }
        }
    }
    .controls-panel {
        position: absolute;
        bottom: 5px;
        left: calc((100% - 253px) / 2);
        transform: translateX(-50%);
        width: 150px;
        height: 40px;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 20px;
        z-index: 2;
        color: #fff;
        &:hover {
            background: rgba(0,0,0,0.6);
        }
        .larger {
            position: absolute;
            left: 37px;
            top: 50%;
            transform: translateY(-50%);
        }
        .smaller {
            position: absolute;
            right: 37px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
}
.audio {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.material-block {
    margin-bottom: 20px;
    height: 166px;
    .img-wrap {
        display: inline-block;
        position: relative;
        margin-right: 20px;
        height: 166px;
        width: 295px;
        background: #cfcfcf;
    }
    .info {
        display: inline-block;
        vertical-align: bottom;
        font-weight: 700;
    }
}
.ivu-modal-mask, .ivu-modal-wrap {
    z-index: 9999 !important;
}
.wrap .ivu-modal-mask, .wrap .ivu-modal-wrap {
    z-index: 1000 !important;
}
</style>