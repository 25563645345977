<template>
    <div class="noData-wrap">
        <img class="image-center" src="~assets/noData.png">
        <p class="noData-text">{{ text }}</p>
    </div>  
</template>
<script>
    export default {
        name: "",
        mixins: [],
        components: {},
        props: {
            text: {
                default: ''
            }
        },
        data () {
            return {}
        },
        computed: {},
        methods: {},
        created() {}
    }
</script>
<style scoped lang="less">
.noData-wrap {
    position: relative;
    margin: 0 auto;
    padding-bottom: 10px;
    width: 210px;
    height: 260px;
    .noData-text {
        position: absolute;
        left: 50%;
        bottom: 10px;
        transform: translateX(-50%);
        font-size: 14px;
        line-height: 20px;
        text-align: center;
    }
}
</style>