<template>
    <div id="processed" ref="processed" :style="{ 'height': height  }">
        <slot></slot>
        <div :class="['footer']">
            <Spin v-if="loading" fix>
                <Icon type="ios-loading" size=18 class="demo-spin-icon-load"></Icon>
                <div>{{ loadingText }}</div>
            </Spin>
            <div class="no-data-text" v-if="disabled">{{ noDataText }}</div>
        </div>
    </div>
</template>
<script>

    export default {
        name: "",
        mixins: [],
        components: {},
        props: {
            height: {
                type: String,
                default: '100%'
            },
            onRefresh: {
              type: Function,
              default: undefined,
              required: false
            },
            disabled: {
                default: false
            },
            loadingText: {
                type: String,
                default: '加载中'
            },
            noDataText: {
                type: String,
                default: '没有更多数据'
            }
        },
        data () {
            return {
                auditModalShow: false,
                loading: false,
                lastScroll: 0,
                reachedBottomScrollLimit: false,
                test: false,
            }
        },
        computed: {},
        watch: {},
        methods: {
            infiniteDone() {
                this.loading = false
            },
            boxScroll() {
                let scrollTop = this.$refs.processed.scrollTop,
                    scrollHeight = this.$refs.processed.scrollHeight,
                    windowHeight = this.$refs.processed.clientHeight;
                const scrollDirection = Math.sign(this.lastScroll - scrollTop);//判断滚动方向
                
                if (this.loading || this.disabled) return

                //是否滚动到底部的判断
                let diff = scrollHeight - (scrollTop + windowHeight)
                if (scrollDirection == -1 && diff <= 10 && diff >= 0) {
                    this.reachedBottomScrollLimit = true
                    this.loading = true
                    this.onRefresh(this.infiniteDone)
                }
                this.lastScroll = scrollTop
            },
            destory() {
                this.$refs.processed.removeEventListener('scroll', this.boxScroll, false)
            }
        },
        beforeDestroy() {
            this.$refs.processed.removeEventListener('scroll', this.boxScroll, false)
        },
        mounted() {
            this.$nextTick(() => {
                this.$refs.processed.addEventListener('scroll', this.boxScroll, false)
            })
        }
    }
</script>
<style scoped lang="less">
@import "~less/color";
#processed {
    overflow-y: auto;
    overflow-x: hidden;
}
.footer {
    position: relative;
    margin: 10px 0;
    text-align: center;
    transition: all 1s;
    .no-data-text {
        font-size: 14px;
    }
}
.footer-loading {
    padding: 20px 0;
    height: 50px;
}
.demo-spin-icon-load{
    animation: ani-demo-spin 1s linear infinite;
}
@keyframes ani-demo-spin {
    from { transform: rotate(0deg);}
    50%  { transform: rotate(180deg);}
    to   { transform: rotate(360deg);}
}
</style>