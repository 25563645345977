<template>
    <Modal
        v-model="modal"
        class="wrap"
        :mask-closable="false"
        :transfer="transfer"
        :width="width"
        @on-visible-change="handleVisibleChange"
    >
        <div slot="header">
            <Icon class="title-icon" type="ios-help-circle" size="28"></Icon>
            <span class="modal-title">{{ title }}</span>
        </div>
        <div class="content">{{ content }}</div>
        <div slot="footer" style="margin-right: 15%;">
            <Button type="text" @click="cancel">{{ cancelText }}</Button>
            <Button type="primary" :loading="loading" @click="submit">{{ okText }}</Button>
        </div>
    </Modal> 
</template>
<script>
    export default {
        name: "",
        mixins: [],
        components: {},
        props: {
            show: {
                default: false
            },
            transfer: {
                default: false
            },
            width: {
                default: 416
            },
            title: {
                default: ''
            },
            content: {
                default: ''
            },
            okText: {
                default: ''
            },
            cancelText: {
                default: ''
            },
            handleParentSubmit: Function
        },
        data () {
            return {
                modal: false,
                loading: false
            }
        },
        computed: {},
        watch: {
            show(val) {
                this.modal = val
            }
        },
        methods: {
            handleVisibleChange(val) {
                if (!val) {
                    this.cancel()
                }
            },
            cancel() {
                let _this = this
                setTimeout(() => {
                    _this.loading = false
                }, 500)
                this.$emit('cancel')
            },
            submit() {
                this.loading = true
                this.handleParentSubmit(this.cancel)
            }
        },
        created() {}
    }
</script>
<style scoped lang="less">
.title-icon {
    color:#f90;
    vertical-align: middle;
}
.modal-title {
    vertical-align: middle;
    margin-left: 12px;
    font-size: 16px;
    color: #17233d;
    font-weight: 500;
}
.content {
    text-align: center;
}
</style>